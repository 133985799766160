import React, { useContext, useEffect, useState } from "react";
import "./Modal.css";
// import { Signer, ethers } from "ethers";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Buyicon from "../../images/buy.svg";
import PinConfirm from "./PinConfirm";
import usdcAbi from "../../Contracts/usdc.json";
import { IoCloseSharp } from "react-icons/io5";
import configURl from "../../runtime.config";
import { ConversionContext } from "../../Context/context";

// import { BigNumber } from "ethers";
import {
  getConversionRate,
  requestRedToken,
} from "../../services/propertyServices";
import { usdcAddress } from "../../Contracts/usdc";
// import Web3 from "web3";
// import { getContractInstance, getConvertedAmount } from "../../web3/web3";
import {
  fireSuccessToast,
  fireToast,
  kycErrorPopUp,
  maintenancemodePopup,
} from "../../common/Toster";
import { toast } from "react-toastify";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";

const Buy = () => {
  const signer = useSigner();
  const address = useAddress();
  const [loader, setLoader] = useState(false);
  const contextData = useContext(ConversionContext);
  const [show, setShow] = useState(false);
  const isPinActive = contextData?.userData?.isMpinActive;
  // const [userAddress, setUserAddress] = useState(null);
  const [amount, setAmount] = useState("");
  const [redToken, setRedToken] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [adminAddress, setAdminAddress] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const handleClose = () => {
    setShow(false);
  };
  const [showPinModal, setShowPinModal] = useState(false);
  const handleClosePinModal = () => {
    setShowPinModal(false);
  };
  const userData = contextData?.userData;

  const handleShow = async () => {
    const exChangeRate = await getConversionRate();
    // console.log(exChangeRate,'exchange')
    const { conversionrate, receivewalletaddress } = exChangeRate.data;
    setAdminAddress(receivewalletaddress);
    setConversionRate(conversionrate);
    setShow(true);
    // const res = await window.ethereum.request({
    //   method: "eth_requestAccounts",
    // });
    // setUserAddress(res[0]);
  };
  const SendTransactionToAdmin = () => {
    (isPinActive
      ? () => {
          setShow(false);
          setShowPinModal(true);
        }
      : () => {
          buyFunction();
        })();
  };

  async function buyFunction() {
    if (amount <= 0) {
      fireToast("error", "please enter amount...");
    } else {
      try {
        setLoader(true);
        const amt = Number(amount).toFixed(5).toString();
        const amountToBePassed = parseUnits(amt, 6);
        const maticBalance = ethers.utils.formatEther(
          await signer.getBalance()
        );
        // const estimatedGasPrice = ethers.utils.formatEther(
        //   await signer.getGasPrice()
        // );
        // console.log(maticBalance, "maticBalance");
        // console.log(estimatedGasPrice, "estimatedGasPrice");
        if (+maticBalance < configURl.minMaticLimit) {
          fireToast("error", "Insufficient matic funds for gas price !");
          setLoader(false);
          return;
        }
        // const amountToBePassed = BigNumber.from(amount).mul(BigNumber.from(10).pow(6));
        const instance = new ethers.Contract(usdcAddress, usdcAbi, signer);
        const balance = ethers.utils.formatUnits(
          await instance.balanceOf(address),
          6
        );
        // console.log(balance, "formattedBalance");
        if (+balance < +amt) {
          fireToast("error", "Transfer amount exceeds balance !");
          setLoader(false);
          return;
        }
        const tx = await instance.transfer(adminAddress, amountToBePassed, {
          gasLimit: 600000,
        });
        // console.log(tx, "transaction");
        const response = await tx;
        // console.log(response, "transaction receipt");
        if (response) {
          const requestData = {
            accountType: "PRIMARY",
            transactionType: "Buy RED",
            usdc: amount,
            hashId: response.hash,
            totalRedToken: redToken.toString(),
            walletAddress: walletAddress,
          };
          await requestRedToken(requestData).then(
            (response) => {
              setLoader(false);
              // console.log(response);
              handleClose();
              fireSuccessToast("Successfully Done!");
            },
            (error) => {
              setLoader(false);
              console.log(error);
              handleClose();
              toast.error("Transaction Failed!");
            }
          );
        }
      } catch (error) {
        setLoader(false);
        handleClose();
        console.log(error);
      }
    }
  }

  // const getWallet = async () => {
  // if (
  //   window.web3 &&
  //   window.web3.currentProvider &&
  //   window.web3.currentProvider.isCoinbaseWallet
  // ) {
  //   try {
  //     await window.ethereum.request({
  //       method: "wallet_switchEthereumChain",
  //       params: [{ chainId: "0x89" }], // chainId must be in hexadecimal numbers
  //     });
  //   } catch (e) {
  //     // fireToast("error", "Request Rejected.");
  //   }
  // }
  // const web3 = (window.web3 = new Web3(window.ethereum));
  // const accounts = await window.ethereum.request({
  //   method: "eth_requestAccounts",
  // });
  // var account = accounts[0];
  // setWalletAddress(accounts[0]);
  // setWalletAddress(address);
  // };

  useEffect(() => {
    if (address) {
      setWalletAddress(address);
    }
  }, [address]);

  /* facetec kyc hide */
  // const isKycVerified =
  //   localStorage.getItem("isKycCompleted") === "true" ? true : false;

  return (
    <>
      <Button
        className="bg-transparent border-0 p-0"
        variant="primary"
        onClick={() => {
          if (userData?.maintenanceMode) {
            maintenancemodePopup();
          } else {
            handleShow();
          }
        }}
      >
        <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
          <img src={Buyicon} alt="" />
        </span>
        <p className="scard-btext">Buy</p>
      </Button>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Link
                className="back-btn fw-500 text-black"
                // onClick={() => setShow(false)}
              >
                {/* <i className="pe-2 align-middle">
              <FaCircleChevronLeft />
            </i> */}
                Buy RED
              </Link>
            </div>
            <div>
              <span
                onClick={() => setShow(false)}
                style={{ cursor: "pointer", fontSize: "22px" }}
              >
                <IoCloseSharp />
              </span>
            </div>
          </div>

          <div className="px-2 my-4">
            <Form className="row user-form">
              <Form.Group className="col-12 mb-4" controlId="">
                <Form.Label>Enter Red Tokens </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="0"
                  value={redToken}
                  onChange={(e) => {
                    setRedToken(e.target.value);
                    setAmount(e.target.value * conversionRate);
                  }}
                  // disabled
                />
              </Form.Group>
              <Form.Group className="col-12 mb-3" controlId="formBasicEmail">
                <Form.Label>Enter Amount USD</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="0"
                  onChange={(e) => {
                    setAmount(e.target.value);
                    setRedToken(e.target.value / conversionRate);
                  }}
                  disabled={conversionRate == 0}
                  value={amount}
                />
              </Form.Group>

              <Form.Group className="col-12 mb-4" controlId="">
                <Form.Label>Enter Wallet Address </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Wallet address.."
                  value={walletAddress}
                  onChange={(e) => {
                    setWalletAddress(e.target.value);
                  }}
                />
              </Form.Group>

              <div className="col-12">
                {loader ? (
                  <Button
                    // disabled={loader}
                    className="w-100 common-btn"
                    variant="primary"
                  >
                    <span className="typeWriter">
                      processing<span> . . . . .</span>
                    </span>
                  </Button>
                ) : (
                  <Button
                    className="w-100 common-btn"
                    variant="primary"
                    disabled={amount <= 0 && !walletAddress}
                    onClick={SendTransactionToAdmin}
                  >
                    Buy
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <PinConfirm
        setShow={setShow}
        showPinModal={showPinModal}
        handleSubmit={buyFunction}
        handleClosePinModal={handleClosePinModal}
      />
    </>
  );
};

export default Buy;

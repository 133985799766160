import { useState, useEffect, useContext } from "react";
import "./Modal.css";
import { FaCircleChevronLeft } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import send from "../../images/send.svg";
import PinConfirm from "./PinConfirm";
import { ConversionContext } from "../../Context/context";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import SRABI from "../../Abis/SR_ABi.json";
import {
  transaction,
  getAllClientUsers,
} from "../../services/propertyServices";
import { fireToast, kycErrorPopUp, toastConfirm } from "../../common/Toster";
import configURl from "../../runtime.config";
import { ethers } from "ethers";
import { useAddress, useSigner } from "@thirdweb-dev/react";

const SendSr = ({ getBalance, balance }) => {
  const contextData = useContext(ConversionContext);
  const isPinActive = contextData?.userData?.isMpinActive;
  const signer = useSigner();
  const myAddress = useAddress();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [search1, SetSearch] = useState("");
  const [userFullName, SetUserFullName] = useState("");
  const handleClosePinModal = () => {
    setShowPinModal(false);
  };
  const [userData, setUserData] = useState([]);
  const handleClose = () => {
    setShow(false);
    SetUserFullName("");
  };
  const handleShow = () => setShow(true);
  const sendSrSchema = Yup.object().shape({
    userName: Yup.string().required("User name is required"),
    solosReward: Yup.number()
      .max(balance, `Only ${balance} SR available`)
      .required("Amount is required"),
    walletAddress: Yup.string()
      .min("42")
      .max("42")
      .required("Wallet Address is required"),
  });
  const sendOutsideSrSchema = Yup.object().shape({
    solosReward: Yup.number()
      .max(balance, `Only ${balance} SR available`)
      .required("Amount is required"),
    walletAddress: Yup.string()
      .min("42")
      .max("42")
      .required("Wallet Address is required"),
  });

  let userInfo = localStorage.getItem("contact-info") || null;
  userInfo = userInfo ? JSON.parse(userInfo) : null;

  /* facetec kyc hide */
  // const isKycVerified =
  //   localStorage.getItem("isKycCompleted") == "true" ? true : false;

  const onFormSubmit = async (data, actions) => {
    toastConfirm(
      "Are you sure?",
      `You want to send ${data.solosReward} SR tokens to ${
        data?.userName ? data?.userName : data?.walletAddress
      }?`
    )
      .fire()
      .then(async (val) => {
        if (val.isConfirmed) {
          (isPinActive
            ? () => {
                setShow(false);
                setFormData(data);
                setShowPinModal(true);
              }
            : () => {
                sendCustomAssets(data);
              })();

          // sendCustomAssets(data);
        }
      });
  };

  // wallet
  const sendCustomAssets = async (data) => {
    console.log(data, "data");
    try {
      setLoader(true);
      const contractInstance = new ethers.Contract(
        configURl.SR_Address,
        SRABI,
        signer
      );
      const maticBalance = ethers.utils.formatEther(await signer.getBalance());
      // const gasPrice = await signer.getGasPrice();
      const gasPrice = ethers.utils.parseUnits("500", "gwei");
      // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
      if (+maticBalance < configURl.minMaticLimit) {
        fireToast("error", "Insufficient matic funds for gas price !");
        setLoader(false);
        return;
      }
      const decimals = await contractInstance.decimals();
      const srBalance = await contractInstance.balanceOf(myAddress);
      const formatSrBalance = ethers.utils.formatUnits(srBalance, decimals);
      if (+formatSrBalance < +data?.solosReward) {
        fireToast("error", "SR Balance Is Low !");
        setLoader(false);
        return;
      }
      const hexQuantity = ethers.utils.parseUnits(
        data?.solosReward.toString(),
        decimals
      );
      const response = await contractInstance.transfer(
        data?.walletAddress,
        hexQuantity,
        {
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas: gasPrice,
          gasLimit: 600000,
        }
      );
      console.log(response, "transfer response");
      if (response) {
        getBalance();
        setLoader(false);
        transaction({
          walletAddress: data?.walletAddress,
          transactionType: "Solos Reward",
          token: data?.solosReward,
          userName: data?.userName,
          hashId: response?.hash,
        }).then((res) => {
          console.log(res, "res");
        });
        fireToast("success", "Successfully Sent.");
        handleClose();
      }
    } catch (err) {
      fireToast("error", "Something went wrong please try again later !");
      console.log(err, "sendCustomAssets error");
      handleClose();
    } finally {
      setLoader(false);
      handleClose();
    }
  };

  const fetchUserData = async (
    pageNumber = 1,
    pageSize = 5,
    search = search1
  ) => {
    try {
      const list = await getAllClientUsers(pageNumber, pageSize, search);
      if (list.status === 200) {
        setUserData(list?.data?.items);
      } else {
        throw new Error(list.error);
      }
    } catch (err) {}
  };
  //end wallet
  useEffect(() => {
    fetchUserData();
  }, [search1]);

  const handleSearch = (value) => {
    SetSearch(value);
    // console.log(value, "test values");
    fetchUserData();
  };

  return (
    <>
      <Button
        className="bg-transparent border-0 p-0"
        variant="primary"
        onClick={() => {
          // if (isKycVerified) {
            handleShow();
          // } else {
          //   kycErrorPopUp();
          // }
        }}
      >
        <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
          <img src={send} alt="" />
        </span>
        <p className="scard-btext">Send</p>
      </Button>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <span
            className="back-btn fw-500 text-black"
            onClick={() => handleClose()}
          >
            <i className="pe-2 align-middle">
              <FaCircleChevronLeft />
            </i>
            Send SR
          </span>
          <div className="dashboard-centerd">
            <div className="row g-0">
              <div className="col-xl-12 col-xxl-12  pt-4  pb-5">
                <div className="">
                  <Tabs
                    defaultActiveKey="platform"
                    id="sendusdc_tabs"
                    className="marketplace-tabs border-0 mb-4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Tab eventKey="platform" title="Solos Platform">
                      <div className="px-5 my-4">
                        <Formik
                          initialValues={{
                            userName: "",
                            walletAddress: "",
                            solosReward: "",
                            accountType: "Primary",
                          }}
                          validationSchema={sendSrSchema}
                          onSubmit={(values, actions) => {
                            setTimeout(() => {
                              onFormSubmit(values, actions);
                              actions.setSubmitting(false);
                            }, 500);
                          }}
                        >
                          {({
                            handleSubmit,
                            errors,
                            touched,
                            setFieldValue,
                          }) => (
                            <Form
                              onSubmit={handleSubmit}
                              className="row user-form"
                            >
                              <Form.Group
                                className="col-12 mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Enter User Name</Form.Label>
                                <Field
                                  onChange={(e) => {
                                    setFieldValue("userName", e.target.value);
                                    handleSearch(e.target.value);
                                  }}
                                  type="text"
                                  autoComplete="off"
                                  placeholder="Enter User Name"
                                  name="userName"
                                  className="form-ctl"
                                />
                                <h6 className="text-end mb-0 mt-1">
                                  {userFullName}
                                </h6>
                                {search1 == "" ? (
                                  ""
                                ) : (
                                  <div className="customSearch">
                                    {userData.length == 0 ? (
                                      <div> No Data Found. </div>
                                    ) : (
                                      userData?.map((item, i) => (
                                        <div
                                          onClick={() => {
                                            setFieldValue(
                                              "walletAddress",
                                              item?.walletAddress
                                            );
                                            setFieldValue(
                                              "userName",
                                              item?.userName
                                            );
                                            SetUserFullName(
                                              item?.name + " " + item?.lastname
                                            );
                                            SetSearch("");
                                          }}
                                          className="items"
                                          key={i}
                                        >
                                          <div
                                            style={{
                                              padding: "5px 0px",
                                              borderBottom:
                                                "1px solid #2c29294d",
                                            }}
                                          >
                                            {item?.userName}
                                            {" - "}
                                            {item?.name + " " + item?.lastname}
                                          </div>
                                        </div>
                                      ))
                                    )}
                                  </div>
                                )}
                                {errors.userName && touched.userName ? (
                                  <div className="text-danger">
                                    {errors.userName}
                                  </div>
                                ) : null}
                              </Form.Group>
                              <Form.Group className="col-12 mb-4" controlId="">
                                <Form.Label>Enter Wallet Address</Form.Label>
                                <Field
                                  type="text"
                                  placeholder="Enter wallet address"
                                  name="walletAddress"
                                  className="form-ctl"
                                />
                                {errors.walletAddress &&
                                touched.walletAddress ? (
                                  <div className="text-danger">
                                    {errors.walletAddress}
                                  </div>
                                ) : null}
                              </Form.Group>

                              <Form.Group className="col-12 mb-4" controlId="">
                                <Form.Label>Enter SR Amount</Form.Label>
                                <Field
                                  type="number"
                                  placeholder="Enter amount"
                                  name="solosReward"
                                  className="form-ctl"
                                />
                                {errors.solosReward && touched.solosReward ? (
                                  <div className="text-danger">
                                    {errors.solosReward}
                                  </div>
                                ) : null}
                              </Form.Group>

                              <div className="col-12">
                                {loader ? (
                                  <Button
                                    // disabled={loader}
                                    className="w-100 common-btn"
                                    variant="primary"
                                  >
                                    <span className="typeWriter">
                                      processing<span> . . . . .</span>
                                    </span>
                                  </Button>
                                ) : (
                                  <Button
                                    // disabled={loader}
                                    className="w-100 common-btn"
                                    variant="primary"
                                    type="submit"
                                  >
                                    Send
                                  </Button>
                                )}
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </Tab>
                    <Tab eventKey="outsideplatform" title="Outside Solos">
                      <div className="px-5 my-4">
                        <Formik
                          initialValues={{
                            userName: "",
                            walletAddress: "",
                            solosReward: "",
                            accountType: "Primary",
                          }}
                          validationSchema={sendOutsideSrSchema}
                          onSubmit={(values, actions) => {
                            setTimeout(() => {
                              onFormSubmit(values, actions);
                              actions.setSubmitting(false);
                            }, 500);
                          }}
                        >
                          {({
                            handleSubmit,
                            errors,
                            touched,
                            setFieldValue,
                          }) => (
                            <Form
                              onSubmit={handleSubmit}
                              className="row user-form"
                            >
                              <Form.Group className="col-12 mb-4" controlId="">
                                <Form.Label>Enter Wallet Address</Form.Label>
                                <Field
                                  type="text"
                                  placeholder="Enter wallet address"
                                  name="walletAddress"
                                  className="form-ctl"
                                />
                                {errors.walletAddress &&
                                touched.walletAddress ? (
                                  <div className="text-danger">
                                    {errors.walletAddress}
                                  </div>
                                ) : null}
                              </Form.Group>

                              <Form.Group className="col-12 mb-4" controlId="">
                                <Form.Label>Enter SR Amount</Form.Label>
                                <Field
                                  type="number"
                                  placeholder="Enter amount"
                                  name="solosReward"
                                  className="form-ctl"
                                />
                                {errors.solosReward && touched.solosReward ? (
                                  <div className="text-danger">
                                    {errors.solosReward}
                                  </div>
                                ) : null}
                              </Form.Group>

                              <div className="col-12">
                                {loader ? (
                                  <Button
                                    // disabled={loader}
                                    className="w-100 common-btn"
                                    variant="primary"
                                  >
                                    <span className="typeWriter">
                                      processing<span> . . . . .</span>
                                    </span>
                                  </Button>
                                ) : (
                                  <Button
                                    // disabled={loader}
                                    className="w-100 common-btn"
                                    variant="primary"
                                    type="submit"
                                  >
                                    Send
                                  </Button>
                                )}
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <PinConfirm
        setShow={setShow}
        formData={formData}
        showPinModal={showPinModal}
        handleSubmit={sendCustomAssets}
        handleClosePinModal={handleClosePinModal}
      />
    </>
  );
};

export default SendSr;

import React, { useContext, useState } from "react";
import "./Modal.css";
import Button from "react-bootstrap/Button";
import { ConversionContext } from "../../Context/context";
import Modal from "react-bootstrap/Modal";
import PinConfirm from "./PinConfirm";
import Form from "react-bootstrap/Form";
import SrTokenAbi from "../../Abis/SR_ABi.json";
import { Link } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import {
  fireSuccessToast,
  fireToast,
  kycErrorPopUp,
  maintenancemodePopup,
} from "../../common/Toster";
import send from "../../images/send.svg";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import configURl from "../../runtime.config";
import { ethers } from "ethers";

const SellSr = ({ getBalance, balance }) => {
  const signer = useSigner();
  const myAddress = useAddress();
  const contextData = useContext(ConversionContext);
  const userData = contextData?.userData;
  const [loader, setLoader] = useState(false);
  const [tradePrice, setTradePrice] = useState(0);
  const [show, setShow] = useState(false);
  const isPinActive = contextData?.userData?.isMpinActive;
  const [srAmount, setSrAmount] = useState(0);
  const [showPinModal, setShowPinModal] = useState(false);
  const handleClosePinModal = () => {
    setShowPinModal(false);
  };
  
  /* facetec kyc hide */
  // const isKycVerified =
  //   localStorage.getItem("isKycCompleted") === "true" ? true : false;
  const handleClose = () => setShow(false);
  const handleShow = async () => {
    setSrAmount(0);
    setTradePrice(0);
    setShow(true);
  };
  const onSellSR = () => {
    (isPinActive
      ? () => {
        setShow(false);
        setShowPinModal(true);
      }
      : () => {
        sellFunction();
      })();
  };

  const sellFunction = async () => {
    console.log("onSellSR", srAmount, tradePrice);
    try {
      setLoader(true);
      const instance = new ethers.Contract(
        configURl.SR_Address,
        SrTokenAbi,
        signer
      );
      const maticBalance = ethers.utils.formatEther(await signer.getBalance());
      // const gasPrice = await signer.getGasPrice();
      const gasPrice = ethers.utils.parseUnits("500", "gwei");
      // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
      if (+maticBalance < configURl.minMaticLimit) {
        fireToast("error", "Insufficient matic funds for gas price !");
        setLoader(false);
        return;
      }
      const decimals = await instance.decimals();
      const srBalance = await instance.balanceOf(myAddress);
      const formatSrBalance = ethers.utils.formatUnits(srBalance, decimals);
      if (+formatSrBalance < +srAmount) {
        fireToast("error", "SR Balance Is Low !");
        setLoader(false);
        return;
      }

      const newAmount = ethers.utils.parseUnits(`${srAmount}`, decimals);
      console.log(newAmount, "newAmount");
      const transaction = await instance.listSR(+tradePrice, newAmount, {
        maxFeePerGas: gasPrice,
        maxPriorityFeePerGas: gasPrice,
        gasLimit: 600000,
      });
      console.log(transaction, "transaction");
      setLoader(false);
      handleClose();
      getBalance();
      fireSuccessToast("Successfully Done!");
    } catch (error) {
      console.error("SR List Error:", error);
      setLoader(false);
      fireToast("error", "Something went wrong please try again later !");
    }
  };
  return (
    <>
      <Button
        className="bg-transparent border-0 p-0"
        variant="primary"
        onClick={() => {
          if (userData?.maintenanceMode) {
            maintenancemodePopup();
          } 
          // else if (isKycVerified) {
          //   handleShow();
          // } 
          else {
            handleShow();
            // kycErrorPopUp();
          }
        }}
      >
        <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
          <img src={send} alt="" />
        </span>
        <p className="scard-btext">Sell</p>
      </Button>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Link className="back-btn fw-500 text-black">
                Post SR for sale on Solos Marketplace
              </Link>
            </div>

            <div>
              <span
                onClick={() => setShow(false)}
                style={{ cursor: "pointer", fontSize: "22px" }}
              >
                <IoCloseSharp />
              </span>
            </div>
          </div>

          <div className="px-2 my-4">
            <Form className="row user-form" onSubmit={onSellSR}>
              <Form.Group className="col-12 mb-3" controlId="formBasicEmail">
                <Form.Label>How many SR you want to sell?</Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) => {
                    setSrAmount(e.target.value);
                  }}
                  min={0}
                  max={balance}
                  value={srAmount}
                />
              </Form.Group>

              <Form.Group className="col-12 mb-4" controlId="">
                <Form.Label>Enter Selling Price per SR (in RED): </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="0"
                  value={tradePrice}
                  onChange={(e) => {
                    setTradePrice(e.target.value);
                  }}
                />
              </Form.Group>
              <div className="col-12">
                <Button
                  type="submit"
                  disabled={loader || tradePrice <= 0 || srAmount <= 0}
                  className="w-100 common-btn"
                  variant="primary"
                >
                  {loader ? (
                    <span className="typeWriter">
                      processing<span> . . . . .</span>
                    </span>
                  ) : (
                    <>Sell</>
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <PinConfirm
        setShow={setShow}
        showPinModal={showPinModal}
        handleSubmit={sellFunction}
        handleClosePinModal={handleClosePinModal}
      />
    </>
  );
};

export default SellSr;
